<template>
  <div>
    <CreateOrUpdateCommunity />
  </div>
</template>

<script>
  import CreateOrUpdateCommunity from '@components/Community/CreateOrUpdateCommunity'

export default {
  components: {
    CreateOrUpdateCommunity
  },
}
</script>
 